import React from 'react'
import { navigate } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import TemplatePDF from '../files/project-briefing-form.pdf'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class ContactPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formError: false,
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    if (this.state.name && this.state.company && this.state.email) {
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': 'contact',
          ...this.state,
        }),
      })
        .then(() => {
          navigate('/thanks')
        })
        .catch(error => alert(error))
    } else {
      this.setState({ formError: true })
    }
  }

  render() {
    return (
      <Layout>
        <SEO title="Contact Us" />
        <section className="section section--gradient">
          <div className="container">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <h2 className="title is-size-3 has-text-weight-bold is-bold-light has-underline">
                  Contact Us
                </h2>
              </div>
            </div>
            <div className="columns">
              <div className="column is-5 is-offset-1 content contact-column">
                <h3 className="title is-size-4">Contact Details</h3>
                <p className="is-size-4">
                  Woods International
                  <br />
                  Unit U & X Daneholes Roundabout
                  <br />
                  Stanford Road
                  <br />
                  Grays
                  <br />
                  RM16 2XE
                  <br />
                  Office: +44 (0) 20 3198 4049
                  <br />
                  Email: sales@woodsinternational.co.uk
                </p>
                <hr />
                <div className="template-download-wrapper">
                  <p className="is-size-4">
                    If you’d like to brief us on a stand design, you can
                    download and complete our stand design brief template:
                  </p>
                  <p>
                    <a
                      href={TemplatePDF}
                      target="_blank"
                      className="button is-success"
                    >
                      Download
                    </a>
                  </p>
                </div>
              </div>
              <div className="column is-5">
                <h3 className="title is-size-4">Email Us</h3>
                <form
                  name="contact"
                  method="post"
                  action="/thanks/"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  onSubmit={this.handleSubmit}
                >
                  <input type="hidden" name="form-name" value="contact" />
                  <p hidden>
                    <label>
                      Don’t fill this out:{' '}
                      <input name="bot-field" onChange={this.handleChange} />
                    </label>
                  </p>
                  <div className="field">
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        name="name"
                        onChange={this.handleChange}
                        placeholder="Your Name"
                      />
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        name="company"
                        onChange={this.handleChange}
                        placeholder="Your Company"
                      />
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        name="email"
                        onChange={this.handleChange}
                        placeholder="Your Email"
                      />
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        name="telephone"
                        onChange={this.handleChange}
                        placeholder="Telephone"
                      />
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        name="event"
                        onChange={this.handleChange}
                        placeholder="Event"
                      />
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <textarea
                        className="textarea"
                        type="text"
                        name="additional_information"
                        onChange={this.handleChange}
                        placeholder="Additional Information"
                      />
                    </div>
                  </div>
                  {this.state.formError && (
                    <div className="notification is-danger">
                      Name, Company & Email Fields are required.
                    </div>
                  )}
                  <p>
                    <button className="button is-success" type="submit">
                      Send
                    </button>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
